/* 
 * @Author: 曹俊杰 
 * @Date: 2022-03-04 17:32:48
 * @Module: 帮助详情
 */
 <template>
  <div class="helpDetail">
    <el-card shadow="never">
      <h1>{{detail.question}}</h1>
      <h5>{{detail.createdAt|timeFormat("yyyy-mm-dd MM:ss")}}</h5>
      <div class="content" v-html="detail.answer"></div>
    </el-card>
  </div>
</template>
 <script>
import { mapState } from "vuex";
export default {
  components: {},
  data () {
    return {
    };
  },
  computed: {
    ...mapState({
      helpList: state => state.help.helpList
    }),
    detail () {
      const id = this.$route.params.id
      if (this.helpList.length) {
        const index = this.helpList.findIndex(item => item.fqaId == id)
        return this.helpList[index]
      } else {
        return {
          answer: '',
          createdAt: 0,
          fqaId: 0,
          question: "",
          updatedAt: 0
        }
      }

    }
  },
  mounted () {
  },
  methods: {

  },
};
 </script>
 <style lang='scss' scoped>
@import "~@/assets/style/var.scss";
.helpDetail {
  h1 {
    // color: $--color-primary;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
  }
  h5 {
    font-size: 12px;
    color: #aaa;
    text-align: right;
  }
  .content {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    & /deep/ p {
      font-size: 14px;
      text-align: justify;
      line-height: 26px;
      color: #666;
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    & /deep/ img {
      margin-bottom: 20px;
    }
  }
}
</style>